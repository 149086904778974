@use './base/variables' as *;

article {
    margin-bottom: 100px;
}

.about-container {
    p {
        line-height: 2em;
        margin: 0;
    }
    .logo img{
        width: 30%;
        opacity: 0.7 ;
        margin-top: 20px;
    }
    .credits {
        p {
            font-style: italic;
            font-size: 0.9em;
            padding-top: 25px;
            margin: 0;
        }
        a:link,
        a:visited {
            color: $bodyTextColor;
        }
        a:hover, 
        a:active {
            color: #56C7F5;
        }
    }
}

@media (min-width: 750px) {
    .about-container {
        display: flex;
        justify-items: space-between;
        .about-text {
            width: 65%;
            padding: 20px;
            border: 1px solid #56C7F5;
        }
        
        
        .credits {
            width: 35%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

@media (min-width: 1100px) {
    .about-container {
        max-width: 800px;
        margin: 0 auto;
        margin-top: 30px;
        .credits p {
            padding-top: none;
        }
    }
}