@use './base/variables' as *;

.container { 
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 15vmax 0 15vmax;
    row-gap: 6vmax;
    column-gap: 6vmax;
    text-align: center;
    .movie {
        height: auto;
        .poster-container {
            position: relative;
            width: 100%;
            img {
                display: block;
                width: 100%;
                height: auto;
                box-shadow: 0 0 0.05rem rgba(204,204,204,.15), 
                0 0 0.15rem rgba(204,204,204,.15), 
                0 0 0.25rem rgba(204,204,204,.15), 
                0 0 0.35rem rgba(204,204,204,.15), 
                0 0 0.45rem rgba(204,204,204,.15), 
                0 0 0.55rem rgba(204,204,204,.15),
                0 0 0.65rem rgba(204,204,204,.15);      
            }
            .overlay {
                display: block;
                position: absolute;              
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: rgb(216, 213, 213);
                background-image: url(/movie-app/src/images/noise.png);
                color: black;
                
                .movie-desc {
                    position: relative;
                    height: 90%;
                    width: 90%;
                    margin: 5%;
                    h3 {
                        height: 20%;
                    }
                    p {
                        height: 80%;
                        line-height: 1.5;
                        overflow: hidden;
                        margin: 0 5px 0 5px;
                        font-size: 1.5vwax;
                        
                    }
                    p:after {
                        content: "";
                        text-align: right;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 30%;
                        background: linear-gradient(to bottom, rgba(216, 213, 213, 0), rgba(216, 213, 213, 1) 50%);
                        z-index: 80;
                    }
                    .button-container {
                        position: absolute;
                        top: 90%;;
                        margin: 0;
                        width: 100%;
                        min-height: 50px;
                        z-index: 100;
                    }
                    
                    button {
                        border: 1px black dotted;
                        font-size: 1em;
                        border-radius: 5px;
                        outline: none;
                    }
                    button:active,
                    button:focus,
                    button:hover {
                        background-color: #0f698d;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
        .poster-container:hover .overlay {
            opacity: 1;
        }
    }
    .more-info {
        margin-right: 10px;
    }
}

@media (min-width: 600px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
        margin: 0 5vmax 0 5vmax;
        row-gap: 5vmax;
        column-gap: 5vmax;
 
    }
}

@media (min-width: 1024px) {
    .container {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 5vmax;
    }
}
