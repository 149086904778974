@use './base/variables' as *;

.filters {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 2vw 0 2vw;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    font-size: 5vmin;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.filter {
    text-decoration: none;
    position: relative;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5vmax 0 5vmax;
    text-align: left;
}
.filter>a {
    text-decoration: none;
    color: #a4daf0;
    text-shadow: 2px 2px 2px black;
    font-size: 1.2em;
}


.filter>a {
    margin-bottom: 5px;
}

.filter:after {
    content: '';
    height: 4px;
    bottom: -10px;
    left: 0%;
    width: 0%;
    background: #76F9FB;
    box-shadow: 0 1px 1px #76F9FB;
    border-radius: 3px;
    -o-transition:.5s;
  	-ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    
    
}
.filter:hover:after {
    width: 100%;
}
.filter .active {
    color: #56C7F5;
    text-shadow: 2px 2px 2px black;
}

.filter.active:after {
    content: '';
    height: 4px;
    bottom: -10px;
    width: 100%;
    left: 0%;
    width: 0%;
    background: #76F9FB;
    box-shadow: 0 1px 1px;
    border-radius: 3px;    
}

/*a simpler underline with less effect
.filter:hover
.filter:active
.filter:focus {
    color: #56C7F5;
    text-decoration: underline #76F9FB solid;
    text-underline-position: under;
}
*/

// media queries
@media (min-width: 850px) {
    .filters {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 1.3em;
    padding: 0;
    margin-top: 30px;
    .filter{
        display: flex;
    }
}

}