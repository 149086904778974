.NavbarItems {
    background: #35363aa9;
    height: 80px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    margin-bottom: 35px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

}

.navbar-logo {
        
        font-size: 2.1em;
        justify-self: start;
        text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
        text-transform: uppercase;
        margin-left: 20px;
        color: #dad4d4;
        cursor: pointer;
        .active {
            text-decoration: none;
            color: #dad4d4;
        }

}

.nav-menu {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: flex-end;
    margin-right: 2rem;
}



.nav-links {
    color: #dad4d4;
    padding: 15px 20px 15px 20px;
    text-decoration: none;
    text-shadow: 0px 0px 1px rgba(0,0,0,0.2), 0px 1px 2px rgba(0, 0, 0, 0.582);

}

.nav-links:hover {
    background-color: #56C7F5;
    border-radius: 5px;
    transition: all 0.3s ease-out;
}

.fa-bars {
    color:white;
}

.nav-links-mobile{
    display: none;
}

.menu-icon {
    display:none;
}


@media screen and (max-width: 1024px) {
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display:flex ;
        flex-direction: column;
        width: 100%;
        height: 280px;
        position: absolute;
        top:53px;
        left: -100%;
        opacity: 1;
        transition: all 0.3s ease;

    }
    .nav-menu.active {
        background-color: #191919;
        left: 0;
        opacity:1;
        transition: 0.3s ease;
        z-index: 1;
        padding-left: 0;
    }
    .nav-links {
        text-align: center;
        padding: 2rem;
        width:100%;
        display: block;
    }
    .nav-links:hover {
        background-color: #56C7F5;
        border-radius: 0;
    }
    .navbar-logo {
        position: absolute;
        
        left:0;
        
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
}