@use 'variables' as *;

body {
    font-family: $bodyText;
}

h1 {
    font-family: $logoText;
}

p {
    line-height: 1.7em;
}