.container .text-box{
    grid-template: none;
}
.text-box {
    margin-top: 30px;
    h2{
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 50px;    
    margin-left: 20px;
    margin-right: 20px;
}}
.directions-box{
    // display:flex;
    text-align: center;
    margin-bottom: 80px;
    img{
        width: 100%;
    }
    p{
        margin: 0 auto;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 20px;
    }
}
// .directions-left{
//     border: 1px solid #56C7F5;
//     width: 35%;
//     margin: auto;
//     display: block;
//     justify-items: center;
// }
.directions-right{
    border: 1px solid #56C7F5;
    width: 50%;
    margin: auto;
    display: block;
    justify-items: center;
}

@media (min-width: 750px){
    .directions-box p{
        margin-left: 50px;
        margin-right: 50px;
    }
}