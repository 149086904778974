@use './base/variables' as *;

.movie-page-container {
    text-align: center;
    margin: 35px;
    img {
        display: block;
        border: 1px solid #56C7F5;
        margin: 0 auto;
    }
    h1, h3 {
        margin: 5px;
    }
    h1 {
        margin-top: 15px;
        font-size: 1.8em;
        text-align: center;
    }
    .date-rating {
        display: flex;
        justify-content: space-evenly;
        margin: 10px 0px;
    }
    .add-remove-button {
        padding: 10px;
        cursor: pointer;
    }
    #fav_button {
        border: none;
        background-color: #0f698d;
        color: $bodyTextColor;
        font-size: 1em;
        text-align: center;
        margin: 10px;
    }
    p {
        margin: 10px 0px 0px 0px;
        padding: 10px;
        line-height: 2em;
    }
}


@media (min-width: 750px) {
    .movie-page-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .movie-page-info {
        width: 60%;
        padding: 20px;
    }
}

@media (min-width: 1200px) {
    .movie-page-container {
        padding: 15px;
        width: 70%;
        margin: 0 auto;
    }
    img {
        width: 60%;
        margin: 0 auto;
    }
    .movie-page-info {
        h1 {
            font-size: 2em;
            margin-top: none;
        }
    }
}